import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {FiPlus} from 'react-icons/fi'
import {AppDispatch} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {userSelector} from "../../store/user.store.ts";
import {
    formatNumber,
    getHumanDescCurrency,
    getNetworkIcon
} from "../../common/utils/currency.util.ts";
import {balanceSelector, setBalances, setPagination} from "../../store/balance.store.ts";
import TableBalance from "../../common/components/tables/balance/table-balance.component.tsx";
import TablePagination from "../../common/components/tables/table-pagination.component.tsx";
import {Position} from "../../common/enums/position.enum.ts";
import {QueryResult, useMutation, useQuery} from "@apollo/client";
import {GetBalancesResponseType} from "../../common/types/balance/get-balances-response.type.ts";
import {PaginationType} from "../../common/types/store/pagination.type.ts";
import {BalanceType} from "../../common/types/store/balance-state.type.ts";
import {BALANCES_QUERY} from "../../api/graphql/queries/balances.query.ts";
import {toast} from "react-toastify";
import NotificationToast from "../../common/components/toasts/notification.toast.tsx";
import {NotificationType} from "../../common/enums/notification.enum.ts";
import WalletModal from "../../common/components/modals/wallet.modal.tsx";
import DepositModal from "../../common/components/modals/deposit.modal.tsx";
import {UpdateAdminBalancesResponseType} from "../../common/types/balance/update-admin-balances-response.type.ts";
import {UPDATE_ADMIN_BALANCE_MUTATION} from "../../api/graphql/mutations/update-admin-balance.mutation.ts";
import {CurrencyType} from "../../common/enums/currency-type.enum.ts";
import {WalletType} from "../../common/enums/wallet-type.enum.ts";
import {Currency} from "../../common/enums/currency.enum.ts";
import {MdOutlineRepeat} from "react-icons/md";


const BalancePage: FC = () => {
    const dispatcher: AppDispatch = useDispatch();
    const {wallet} = useSelector(userSelector);
    const {balances, headers, pagination: {page, perPage, total}} = useSelector(balanceSelector);

    const [showWalletModal, setShowWalletModal] = useState<boolean>(false);
    const [showDepositModal, setShowDepositModal] = useState<boolean>(false);

    const [updateAdminBalance, updateAdminBalanceData] = useMutation<UpdateAdminBalancesResponseType>(UPDATE_ADMIN_BALANCE_MUTATION);

    const {data, error}: QueryResult<GetBalancesResponseType> = useQuery<GetBalancesResponseType>(BALANCES_QUERY, {
        variables: {
            dto: {page, perPage}
        }
    });

    useEffect((): void => {
        if (data) {
            const response: BalanceType[] = data.getBalanceOperations.data;
            const meta: PaginationType = data.getBalanceOperations.meta;
            dispatcher(setBalances(response));
            dispatcher(setPagination(meta));
        }
    }, [data, dispatcher]);

    useEffect((): void => {
        if (error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="Payments" message="Failed to fetch operations"/>, {
                toastId: 'balances-fetch-error'
            })
        }
    }, [error]);

    useEffect((): void => {
        if (updateAdminBalanceData.error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="Balance" message={updateAdminBalanceData.error.message}/>, {
                toastId: 'update-admin-balances-token-error'
            })
        }
    }, [updateAdminBalanceData.error]);

    const onChangePage = useCallback((page: number): void => {
        dispatcher(setPagination({
            page, perPage, total
        }))
    }, [dispatcher, perPage, total]);

    const updateAdminBalanceHandler = useCallback(async (type: WalletType, currency: Currency): Promise<void> => {
        await updateAdminBalance({
            variables: {type, currency}
        })
    }, [updateAdminBalance]);

    const balancesHeader = useMemo(() => {
        return wallet
            .accounts
            .slice()
            .sort((a, b) => a.type.localeCompare(b.type))
            .map((wallet) => {
                return {
                    type: wallet.type,
                    balances: wallet.balances
                        .slice()
                        .sort((a, b) => a.currency.localeCompare(b.currency))
                        .map((balance) => {
                            return {
                                currency: balance.currency,
                                currencyType: balance.currencyType,
                                balance: balance.balance,
                            }
                        })
                }
            });
    }, [wallet.accounts]);

    return (
        <main>
            <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">

                {/* Page header */}
                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative px-[32px] py-[21px]">
                    <div className="flex items-center justify-between">

                        <div className="flex items-center justify-start">
                            { balancesHeader
                                .map((item, index) => (
                                    <div key={index} className='flex items-center justify-center gap-2 w-full'>
                                        <div className='flex justify-center w-[45px] h-[45px]'>
                                            <img className=" rounded-full" src={getNetworkIcon(item.type)} alt={item.type}/>
                                        </div>
                                        <div className="flex flex-col items-start justify-center mr-[24px]">
                                            {item.balances
                                                .map((balanceItem, i) => (
                                                    <div key={index + '-' + i}
                                                         className={`flex items-center justify-start gap-1`}>
                                                        <p className='text-left text-sm text-gray-700 font-medium m-0'>{formatNumber(balanceItem.balance)}</p>
                                                        <div className='leading-tight flex items-start'>
                                                            <p className='text-center text-[12px] text-gray-500 m-0 uppercase'>{getHumanDescCurrency(balanceItem.currency)}</p>
                                                        </div>
                                                        <button
                                                            disabled={updateAdminBalanceData.loading}
                                                            onClick={() => updateAdminBalanceHandler(item.type, balanceItem.currency)}
                                                            className={`${updateAdminBalanceData.loading && 'pointer-events-none opacity-80'} cursor-pointer transition-all text-indigo-500 mr-2 active:scale-100 hover:scale-110`}
                                                        >
                                                            <MdOutlineRepeat size={16} />
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>

                                ))}
                        </div>

                        <div className='flex items-center justify-end'>
                            <div className='flex items-center'>
                                <button
                                    onClick={() => setShowWalletModal(true)}
                                    className="btn-lg bg-green-500 hover:bg-green-600 text-white"
                                >
                                    <FiPlus className="w-4 h-4 text-white mr-2" />
                                    Deposit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="px-4 sm:px-6 lg:px-8 pb-8 pt-4 w-full max-w-9xl mx-auto">
                <TableBalance
                    headers={headers}
                    items={balances}
                    emptyMessage="You have no payments"
                />

                <div className="mt-8">
                    <TablePagination
                        total={total}
                        currentPage={page}
                        onPageChange={onChangePage}
                        perPage={perPage}
                        position={Position.END}
                    />
                </div>
            </div>
            <WalletModal show={showWalletModal} setShow={setShowWalletModal} setShowDepositModal={setShowDepositModal} currencyTypes={[CurrencyType.COIN]} />
            <DepositModal show={showDepositModal} setShow={setShowDepositModal} />
        </main>
    );
};

export default BalancePage;
