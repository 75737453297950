import { WalletType } from "../enums/wallet-type.enum.ts";

import TronIcon from "../../assets/icon/tron.svg";
import BnbIcon from "../../assets/icon/bnb.svg";
import TonIcon from "../../assets/icon/ton.svg";
import UsdtIcon from "../../assets/icon/usdt.png";
import UserAvatar from "../../assets/img/user-avatar-32.png";
import { Currency } from "../enums/currency.enum.ts";

export const getNetworkIcon = (type: WalletType | undefined): string => {
	switch (type) {
		case WalletType.TRON:
			return TronIcon;
		case WalletType.BSC:
			return BnbIcon;
		case WalletType.TON:
			return TonIcon;
		default:
			return UserAvatar;
	}
};

export const getIconByCurrency = (currency: Currency | undefined): string => {
	switch (currency) {
		case Currency.TRC20:
		case Currency.BEP20:
		case Currency.ERC20:
		case Currency.JETTON:
			return UsdtIcon;
		case Currency.TRX:
			return TronIcon;
		case Currency.BNB:
			return BnbIcon;
		case Currency.TON:
			return TonIcon;
		default:
			return UserAvatar;
	}
};

export const getDescriptionByCurrency = (
	currency: Currency | undefined,
): string => {
	switch (currency) {
		case Currency.TRX:
			return "TRX (TRON Network)";
		case Currency.BNB:
			return "BNB (BSC Network)";
		case Currency.TRC20:
			return "USDT TRC20 (TRON Network)";
		case Currency.BEP20:
			return "USDT BEP20 (BSC Network)";
		case Currency.TON:
			return "TON (Open Network)";
		case Currency.JETTON:
			return "USDT JETTON (TON Open Network)";
		default:
			return "Unknown";
	}
};

export const getHumanDescCurrency = (
	currency: Currency | undefined,
): string => {
	switch (currency) {
		case Currency.TRC20:
		case Currency.BEP20:
		case Currency.ERC20:
		case Currency.JETTON:
			return "USDT";
		case Currency.TRX:
			return "TRX";
		case Currency.BNB:
			return "BNB";
		case Currency.TON:
			return "TON";
		default:
			return "Unknown";
	}
};

export const getHumanAmount = (
	amount: string,
	currency: Currency | undefined,
): string => {
	switch (currency) {
		case Currency.TRX:
		case Currency.BNB:
			return formatNumber(amount, 5) + ` ${getHumanDescCurrency(currency)}`;
		case Currency.TRC20:
		case Currency.BEP20:
			return formatCurrency(amount, 5) + ` ${getHumanDescCurrency(currency)}`;
		default:
			return "Unknown";
	}
};

export const getDescriptionByType = (type: WalletType | undefined): string => {
	switch (type) {
		case WalletType.TRON:
			return "TRON Network";
		case WalletType.BSC:
			return "BSC Network";
		case WalletType.TON:
			return "TON Network";
		default:
			return "Unknown";
	}
};

export const getCoinNameWIthTether = (type: WalletType | undefined): string => {
	switch (type) {
		case WalletType.TRON:
			return "TRX/USDT(TRC20)";
		case WalletType.BSC:
			return "BNB/USDT(BEP20)";
		case WalletType.TON:
			return "TON/USDT(JETTON)";
		default:
			return "Unknown";
	}
};

// @todo add network type
export const getExplorerLinkByType = (
	type: WalletType | undefined,
	value: string,
): string => {
	switch (type) {
		case WalletType.BSC:
			return `https://bscscan.com/address/${value}`;
		default:
			return "Unknown";
	}
};

export const formatCurrency = (
	amount: string | number,
	digits = 2,
	locale = "en-US",
	currency = "USD",
) => {
	const numberAmount = Number(amount);
	if (isNaN(numberAmount))
		throw Error(`Невозможно преобразовать "${amount}" в число`);

	const formatter = new Intl.NumberFormat(locale, {
		style: "currency",
		currency: currency,
		maximumFractionDigits: digits,
	});
	return formatter.format(numberAmount);
};

export const formatNumber = (
	amount: string | number,
	digits = 5,
	locale = "en-US",
) => {
	const numberAmount = Number(amount);
	if (isNaN(numberAmount))
		throw Error(`Невозможно преобразовать "${amount}" в число`);

	const formatter = new Intl.NumberFormat(locale, {
		style: "decimal",
		maximumFractionDigits: digits,
	});
	return formatter.format(numberAmount);
};
